import React from 'react';
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import POD from "../images/PodC5.png"
import Nav from "./NewNav.js"
import BG from "../images/bgnd.png"

import { Container, Image, Row, Col} from 'react-bootstrap'

const NewHeader = () => {

    return(
        <div style={{backgroundImage: `url(${BG})`, backgroundSize: "cover"}} className="pb-20">
         
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Are you ready to meet Jesus Christ. He is coming soon?" />
            <title>ReaCH | Home</title>
            <link rel="canonical" href="https://reachun.org/testpage" />
            <script defer type='text/javascript' charset='utf-8' src='https://www.buzzsprout.com/1818923.js?artist=&container_id=buzzsprout-small-player-limit-1&limit=1&player=small'></script>
        </Helmet>

        <Nav />

       <Container>
           <Row>
                <Col lg={6}>
                    <div className="xs:mt-10 lg:mt-14">
                        <h1 className="xs:text-3xl sm:text-5xl xl:text-6xl font-playfairdisplay font-bold text-blue-dark">Connected to Divine <br /> Power</h1>
                    </div>
                    <div>
                        <p className="text-gray-500 mt-4 font-opensans">
                           Everyone wants to have an awesome day, but that dosen't always happen.
                           However, an awesome start to your day can help you through it. 
                        </p>
                    </div>
                    <div className="">
                    <div id='buzzsprout-small-player-limit-1' className="mt-10"></div>
                  
                    </div>
                    <Link to="/devotions">
                    <div className="font-opensans font-thin rounded-full text-white  py-2 px-3 w-48 flex items-center justify-center bg-orange-light">
                       <p className="m-0">Browse all Devotions</p>
                    </div>
                    </Link>
                </Col>
                <Col className="xs:hidden lg:block">
                    
                    <Link to="/devotions">
                    <div className="mt-4">
                        <Image src={POD} alt="podcast" className="lg:w-96 xl:w-104  ml-20 lg:mt-12 xl:mt-10  object-cover"/>
                    </div>
                    </Link>
                </Col>
            </Row>
       </Container>
       </div>
    )
}

export default NewHeader