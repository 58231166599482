import React from 'react';

import Header from "../components/NewHeader"




const test = () => {
  return(
    
    <div>
     
       <Header />
        
    </div>
    
  )
    
}

export default test